<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            :title="title"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onBack"
        />
      </header>
    </div>

    <div class="section-box bz-content">
      <h2 style="text-align: center;height: 40px; line-height: 40px;">{{title}}</h2>
      <div style="line-height: 30px;height: 30px;font-size: 14px;color: #cccccc">{{createDate}} <span>昆明自来水集团</span></div>
      <div v-html="content"></div>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>
<script>
import Loading from "../../components/logding";
import {getHelpDetail} from "../../api/other";

export default {
  name: "helpDetail",
  components: { Loading },
  data(){
    return{
      id: this.$route.query.id,
      isShow : true,
      lodingTxt: '加载中...',
      content:'',
      title:this.$route.query.title,
      createDate:''
    }
  },
  created() {
    this.getHelpDetail()
  },
  methods:{
    getHelpDetail(){
      const that = this
      that.isShow = true
      getHelpDetail(that.id).then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          if (response.data){
            that.content = response.data.content
            that.createDate = response.data.createDate
          }
        }
      }).catch(() => {
        that.isShow = false
      })
    },
    onBack : function (){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>